import React from 'react'
import './MemberBackground.css'
import object6 from '../../images/objects/object6.png'
import object7 from '../../images/objects/object7.png'

const MemberBackground = () => {
  return (
    <div className="Member-background-container">
      <img src={object6} alt="Description of object 6" className="background-object6" />
      <img src={object7} alt="Description of object 7" className="background-object7" />
    </div>
  )
}

export default MemberBackground