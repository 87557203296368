import React from 'react';
import WorksContent from './WorksContent';
import './Works.css';
import wimage1 from '../../../images/materials/oshimap1.png';
import wimage2 from '../../../images/materials/oshikatsuroom1.png';
import wimage3 from '../../../images/materials/VirtualavatarCreative1.png';
import wimage4 from '../../../images/materials/【BEB5土浦】ぬい活自転車.jpg';

const worksData = [
  {
    image: wimage1,
    title: "推しMAP",
    description: "自分の推しを普段の推し活と共にアプリ内で布教し、他のユーザーが新たな推し活と出会い、より楽しい推し活ができることを促進し合う場所を作るアプリです。",
    appStoreLink: "https://apps.apple.com/jp/app/%E6%8E%A8%E3%81%97%E3%83%9E%E3%83%83%E3%83%97-%E6%8E%A8%E3%81%97%E6%B4%BB%E6%95%99%E3%81%88%E5%90%88%E3%81%84%E3%82%A2%E3%83%97%E3%83%AA/id6450089466",
    playStoreLink: "https://play.google.com/store/search?q=%E6%8E%A8%E3%81%97%E3%83%9E%E3%83%83%E3%83%97&c=apps&hl=ja"
  },
  {
    image: wimage2,
    title: "推し活ルーム",
    description: "星野リゾートBEB5軽井沢様と共同で「ノープランでもOK!? 推し活仲間とすきな時間にすきな過ごし方ができる」をコンセプトとし、「推し活ルーム」のプロデュースを行いました。<br />"
  },
  {
    image: wimage4,
    title: "いばらき絶景<br />推しぬい旅",
    description: "星野リゾートBEB5土浦様の「陸・湖・空」の3つのアクティビティで茨城の絶景を「推しぬい」と一緒に楽しめる「いばらき絶景推しぬい旅」の監修を行いました。<br />"
  },
  // {
  //   image: wimage3,
  //   title: "花織 紗枝",
  //   description: "墨田区から受注を受けバーチャルアバター「花織紗枝（はなおり さえ）」デザイン及びモデル制作を行いました。<br />墨田区の特色を生かしたデザインで親しみやすさを意識してデザインさせていただきました。",
  // }
];

const Works = () => {
  return (
    <div className="works-container">
      <div className="works-title">
        <h1 className="works-title-en">WORKS</h1>
        <p className="works-title-jp">実績</p>
      </div>
      <div className="works-grid">
        {worksData.map((work, index) => (
          <WorksContent key={index} {...work} />
        ))}
      </div>
    </div>
  );
}

export default Works;