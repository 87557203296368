import React from 'react'
import './Footer.css';
import Logo_black from '../../images/Logo_black.png';
import { Link, useNavigate, useLocation } from 'react-router-dom';

const Footer = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const scrollToSection = (sectionId) => {
    if (window.location.pathname !== "/") {
      navigate("/", { state: { scrollTo: sectionId } });
    } else {
      performScroll(sectionId);
    }
  }

  const performScroll = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  }

  React.useEffect(() => {
    if (location.state?.scrollTo) {
      performScroll(location.state.scrollTo);
    }
  }, [location]);

  return (
    <footer>
      <div className="footer">
        <Link to="/">
          <img src={Logo_black} className='Logo' alt="Logo" />
        </Link>
        <ul className="footer-list">
          <li className="footer-list-item">
            <Link to="/Corporate">企業情報</Link>
          </li>
          <li className="footer-list-item">
            <a onClick={() => scrollToSection('news-section')}>ニュース</a>
          </li>
          <li className="footer-list-item">
            <a onClick={() => scrollToSection('business-section')}>事業</a>
          </li>
          <li className="footer-list-item">
            <a onClick={() => scrollToSection('contact-section')}>お問い合わせ</a>
          </li>
        </ul>
      </div>
    </footer>
  )
}

export default Footer