import React from 'react';
import './NewsBackground.css';
import object9 from '../../images/objects/object9.png';

const NewsBackground = () => {
  return (
    <div className='NewsBackground' style={{ backgroundImage: `url(${object9})` }}></div>
  );
}

export default NewsBackground;
