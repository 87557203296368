import React from 'react'
import './InfoBackground.css'
import object10 from '../../images/objects/object10.png'
import object8 from '../../images/objects/object8.png'

const InfoBackground = () => {
  return (
      <div className="Info-background-container">
        <img src={object10} alt="Description of object 10" className="background-object10" />
        <img src={object8} alt="Description of object 8" className="background-object8" />
      </div>
  )
}

export default InfoBackground