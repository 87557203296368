import React from 'react'
import Business from '../Content/BUSINESS/Business'
import Works from '../Content/WORKS/Works'
import Contact from '../Content/CONTACT/Contact'
import News from '../Content/NEWS/News'
import CompanyTop from '../CompanyTop/CompanyTop'
import NewsBackground from '../Background/NewsBackground'
import WorksBackground from '../Background/WorksBackground'

const HomePage = () => {
  return (
    <div>
      <CompanyTop />
      <NewsBackground />
      <div id="news-section"><News /></div>
      <div id="business-section"><Business /></div>
      <WorksBackground />
      <div id="works-section"><Works /></div>
      <div id="contact-section"><Contact /></div>
    </div>
  )
}


export default HomePage