import React from 'react'
import './Member.css'

const Member = () => {
  return (
    <div className="member">
      <div className="member-title">
        <h1 className="member-title-en">MEMBER</h1>
        <p className="member-title-jp">役員紹介</p>
      </div>

      <div className="member-item">
        <p className="member-name">福島 翔和</p>
        <p>代表取締役 <br></br>最高経営責任者 | Chief Executive Officer</p>
      </div>

      <div className="member-item">
        <p className="member-name">土屋 拓巳</p>
        <p>取締役<br></br>最高執行責任者 | Chief Operating Officer</p>
      </div>

      <div className="member-item">
        <p className="member-name">竹田 啓登</p>
        <p>取締役<br></br>最高技術責任者 | Chief Technology Officer</p>
      </div>

    </div>
  )
}

export default Member