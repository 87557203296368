import React from 'react';
import './NewsContent.css';

const NewsContent = ({ date, text, link }) => {
  return (
    <div className="news-content">
      <div className="grey-divider"></div>
      <a href={link} target="_blank" rel="noopener noreferrer" className="news-link">
        <div className="news">
          <p className="news-day">{date}</p>
          <p className="news-text">{text}</p>
        </div>
      </a>
    </div>
  );
}

export default NewsContent;