import React from 'react'
import './WorksBackground.css'
import object1 from '../../images/objects/object1.png'
import object2 from '../../images/objects/object2.png'

const WorksBackground = () => {
  return (
    <div className="works-background-container">
      <img src={object1} alt="Description of object 1" className="background-object1" />
      <img src={object2} alt="Description of object 2" className="background-object2" />
    </div>
  )
}

export default WorksBackground
