import React from 'react'
import './Contact.css';

const Contact = () => {
  const openContactForm = () => {
    window.open('//forms.gle/JqTT8ayhaEhvUSx86', '_blank');
  }

  return (
    <div className="contact-subitem">
      <div className="contact-background">
        <div className="contact">
            <div className="contact-title">
                <h1 className="contact-title-en">CONTACT</h1>
                <p className="contact-title-jp">お問い合わせ</p>
            </div>
            <p className='contact-text'>お問い合わせやご依頼は、お気軽に以下のフォームからご連絡ください</p>
            <button className="custom-button" onClick={openContactForm}>お問い合わせはこちら</button>
        </div>
      </div>
    </div>
  )
}

export default Contact