import React from 'react';
import './WorksContent.css';
import appStoreimage1 from '../../../images/iosdownload.svg';
import googleStoreimage1 from '../../../images/google-play-badge.png';

const WorksContent = ({ image, title, description, appStoreLink, playStoreLink }) => {
  return (
    <div className="works-item">
        <img src={image} alt="スクリーンショット" className="works-image"/>
          <h3 className="worksname-title">
            {title.split('<br />').map((text, index) => (
              <React.Fragment key={index}>
                  {text}
                  {index < title.split('<br />').length - 1 && <br />}
              </React.Fragment>
            ))}
            </h3>
          <p className="works-description">
            {description.split('<br />').map((text, index) => (
              <React.Fragment key={index}>
                  {text}
                  {index < description.split('<br />').length - 1 && <br />}
              </React.Fragment>
          ))}
          </p>
          <div className="works-buttons">
              {appStoreLink && (
                  <a href={appStoreLink} target="_blank" rel="noopener noreferrer">
                      <img src={appStoreimage1} alt="App Storeでダウンロード" className="appstore-image" />
                  </a>
              )}
              {playStoreLink && (
                  <a href={playStoreLink} target="_blank" rel="noopener noreferrer">
                      <img src={googleStoreimage1} alt="Google Playでダウンロード" className="googleplay-image" />
                  </a>
              )}
          </div>
    </div>
  );
}

export default WorksContent