import React from 'react'
import './CompanyInfo.css';

const CompanyInfo = () => {
  return (
    <div className="company">

      <div className="header-margin"></div>

      <div className="company-title">
        <h1 className="company-title-en">COMPANY INFO</h1>
        <p className="company-title-jp">会社情報</p>
      </div>

      <div className="company-info">
        <div className="company-item">
          <p className="company-theme">会社名</p>
          <p>株式会社推しメーター</p>
        </div>
        <div className="company-item">
          <p className="company-theme">会社情報</p>
          <p>2022年（令和4年）9月28日</p>
        </div>
        <div className="company-item">
          <p className="company-theme">資本金</p>
          <p>30,113,977円（資本準備金含む）</p>
        </div>
        <div className="company-item">
          <p className="company-theme">事業内容</p>
          <p>エンターテイメント事業，アプリ開発運営事業</p>
        </div>
        <div className="company-item">
          <p className="company-theme">代表者</p>
          <p>福島 翔和</p>
        </div>
        <div className="company-item">
          <p className="company-theme">本社所在地</p>
          <p>〒131-0044 東京都墨田区文花1-18-13</p>
        </div>
      </div>
    </div>
  )
}

export default CompanyInfo