import React from 'react';
import BusinessContent from './BusinessContent';
import './Business.css';
import bimage1 from '../../../images/materials/Development1.webp';
import bimage2 from '../../../images/materials/oshikatsuroom1.png'
import bimage3 from '../../../images/materials/VirtualavatarCreative1.png'
import bimage4 from '../../../images/materials/VITE1.png'

const Business = () => {
  return (
    <div className="business-section">
      <div className='business-background'>
        <div className='business'>
          <div className="business-title">
            <h1 className="business-title-en">BUSINESS</h1>
            <p className="business-title-jp">事業</p>
          </div>
          {/* <BusinessContent
            title="事業名を入力"
            introduction="事業説明を入力"
            image={画像}
            isReversed={true}常時trueで反転時は削除　
          /> */}
          <BusinessContent
            title="推し活コンサルティング事業"
            introduction="主に企業の既存事業×推し活という部分で事業展開のサポートを行う事業です。<br />メンバー全員がZ世代及び推し活をしているという強みを活かし、ファンの心を掴む魅力的なコンテンツを創出します。"
            image={bimage2}
            isReversed={true}
          />
          <BusinessContent
            title="法人向けバーチャルアバター制作事業"
            introduction="法人及び自治体向けのバーチャルアバターの制作から、法人及び地自体内でのバーチャルアバターの活用をサポートする事業です。<br />企業のブランドイメージに合ったVtuberのキャラクターデザインからコンテンツ制作、運用を総合的にサポートします。<br />新たなデジタルコミュニケーション手段としてバーチャルアバターを活用し、顧客とのコミュニケーションを強化し、ブランドの認知度や好感度の向上、効果的なマーケティングを行うことができます。"
            image={bimage3}
          />
          <BusinessContent
            title="eスポーツチーム「VITE」"
            introduction="レインボーシックスシージ部門、ストリーマー部門を持ち、レインボーシックスシージ部門は、国内最上位リーグで戦いを繰り広げています。<br />企業製品のPRやイベント出演などの案件も受け付けております。"
            image={bimage4}
            isReversed={true}
          />
          <BusinessContent
            title="アプリ・Webサービス開発運用保守事業"
            introduction="自社アプリの開発、運営での経験を活かし、アプリやWebサービスの設計、開発、保守運用までをサポートをする事業です。<br />企業が必要とする機能やデザインだけでなく、利用者の体験を含め設計を行い、より良いサービス作りをサポートをします。"
            image={bimage1}
          />
        </div>
      </div>
    </div>
  );
}

export default Business;
