import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Header from './components/Header/Header';
import HomePage from './components/pages/HomePage';
import CorporatePage from './components/pages/CorporatePage';
import Footer from './components/Footer/Footer';
import './App.css'

function App() {
  return (
        <div className="App">
          <BrowserRouter>
          <Header />
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/Corporate" element={<CorporatePage />} />
            </Routes>
          <Footer />
          </BrowserRouter>
        </div>
  )
}

export default App;
