import React from 'react';
import './Header.css';
import Logo_black from '../../images/Logo_black.png';
import { Link, useNavigate, useLocation } from 'react-router-dom';

const toggleMenu = () => {
    const navList = document.querySelector(".nav-list");
    const hamburger = document.querySelector(".hamburger");

    navList.classList.toggle("active");
    hamburger.classList.toggle("change");
}

const Header = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const scrollToSection = (sectionId) => {
        if (window.location.pathname !== "/") {
            navigate("/", { state: { scrollTo: sectionId } });
        } else {
            performScroll(sectionId);
        }
    }

    const performScroll = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    }

    React.useEffect(() => {
        if (location.state?.scrollTo) {
            performScroll(location.state.scrollTo);
        }
    }, [location]);

    return (
        <header>
            <div className="headline">
                <Link to="/">
                    <img src={Logo_black} className='Logo' alt="Logo" />
                </Link>
            </div>
            <div className="hamburger" onClick={toggleMenu}>
                <div className="bar1"></div>
                <div className="bar2"></div>
                <div className="bar3"></div>
            </div>
            <ul className="nav-list">
                <li className="nav-list-item">
                    <Link to="/Corporate">企業情報</Link>
                </li>
                <li className="nav-list-item">
                    <a onClick={() => scrollToSection('news-section')}>ニュース</a>
                </li>
                <li className="nav-list-item">
                    <a onClick={() => scrollToSection('business-section')}>事業</a>
                </li>
                <li className="nav-list-item">
                    <a onClick={() => scrollToSection('contact-section')}>お問い合わせ</a>
                </li>
            </ul>
        </header>
    )
}

export default Header;
