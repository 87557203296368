import React, { useEffect } from 'react';
import './CompanyTop.css';

const CompanyTop = () => {
  useEffect(() => {
    // テキストアニメーション処理
    const textContainer = document.querySelector('.text-container');
    textContainer.innerHTML = '';
    const text = '推しの力で\n世界中の心を動かす';

    for (let i = 0; i < text.length; i++) {
      if (text[i] === '\n') {
        const breakSpan = document.createElement('span');
        breakSpan.classList.add('break-line');
        textContainer.appendChild(breakSpan);
        continue;
      }

      const span = document.createElement('span');
      span.innerText = text[i];
      span.classList.add('letter');
      span.style.animationDelay = `${0.2 + i * 0.08}s`;
      textContainer.appendChild(span);
    }

    // スムーズスクロール処理
    const handleSmoothScroll = (e) => {
      e.preventDefault();
      const targetId = e.currentTarget.getAttribute("href").substring(1);
      const targetElement = document.getElementById(targetId);

      if (targetElement) {
        window.scrollTo({
          top: targetElement.offsetTop,
          behavior: "smooth",
        });
      }
    };

    const anchorLinks = document.querySelectorAll('a[href^="#"]');
    anchorLinks.forEach((link) => {
      link.addEventListener("click", handleSmoothScroll);
    });

    // ホバー効果処理
    const newsItems = document.querySelectorAll(".news");
    newsItems.forEach((item) => {
      item.addEventListener("mouseenter", () => {
        item.style.transform = "scale(1.1)";
        item.style.backgroundColor = "#cbf7f5";
      });

      item.addEventListener("mouseleave", () => {
        item.style.transform = "scale(1)";
        item.style.backgroundColor = "transparent";
      });
    });

    return () => {
      anchorLinks.forEach((link) => {
        link.removeEventListener("click", handleSmoothScroll);
      });

      newsItems.forEach((item) => {
        item.removeEventListener("mouseenter", () => {});
        item.removeEventListener("mouseleave", () => {});
      });
    };
  }, []);

  return (
    <div className="top-container">
      <div className="background-image image-1"></div>
      <div className="background-image image-2"></div>
      <div className="background-image image-3"></div>
      <div className="background-image image-4"></div>
      <div className="background-image image-5"></div>
      <div className="background-image image-6"></div>
      <div className="background-image image-7"></div>
      <div className="background-image image-8"></div>
      <div className="background-image image-9"></div>
      <div className="background-image image-10"></div>
      <div className="text-container"></div>
    </div>
  );
}

export default CompanyTop;