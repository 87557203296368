import React from 'react';
import './BusinessContent.css';

const BusinessContent = ({ title, introduction, image, isReversed }) => {
  const openContactForm = () => {
    window.open('//forms.gle/JqTT8ayhaEhvUSx86', '_blank');
  }
  const itemClass = isReversed ? "business-item reversed" : "business-item";
  return (
    <div className={itemClass}>
      <div className="business-image" style={{ backgroundImage: `url(${image})` }}></div>
      <div className="business-content">
        <h2 className='business-content-title'>
          {title.split('<br />').map((text, index) => (
            <React.Fragment key={index}>
              {text}
              {index < title.split('<br />').length - 1 && <br />}
            </React.Fragment>
          ))}
        </h2>
        <p className='business-introduction'>
          {introduction.split('<br />').map((text, index) => (
            <React.Fragment key={index}>
              {text}
              {index < introduction.split('<br />').length - 1 && <br />}
            </React.Fragment>
          ))}
        </p>
        <button className="business-button" onClick={openContactForm}>ご依頼はこちらから</button>
      </div>
    </div>
  );
}

export default BusinessContent;
