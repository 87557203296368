import React from 'react'
import CompanyInfo from '../Content/COMPANYINFO/CompanyInfo'
import Member from '../Content/MEMBER/Member'
import InfoBackground from '../Background/InfoBackground'
import MemberBackground from '../Background/MemberBackground'

const CorporatePage = () => {
  return (
    <div>
      <InfoBackground/>
      <CompanyInfo/>
      <MemberBackground/>
      <Member/>
    </div>
  )
}

export default CorporatePage